import { HeaderSideImage, HeaderTextOverlay } from "./components";
import PropTypes from "prop-types";
import useIsMobile from "@/hooks/useIsMobile";
import { useRouter } from "next/router";

export default function Header({ className, title, description, img, cta, back, variant, reading_time }) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const isJobPage = router.pathname === "/jobs";

  switch (variant) {
    case "50/50":
      return (!(isJobPage && isMobile) && <HeaderSideImage className={className} title={title} description={description} img={img} cta={cta} back={back} reading_time={reading_time} />)
    case "Under Text":
    default:
      return (!(isJobPage && isMobile) && <HeaderTextOverlay className={className} title={title} description={description} img={img} back={back} />)
  }
}

Header.defaultProps = {
  variant: "Under Text",
};

Header.propTypes = {
  variant: PropTypes.oneOf(["50/50", "Under Text"]),
};
